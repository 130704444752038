import React from "react";
import AndwendungSvg from "../svg/Anwendung.svg";
import "./homeAnwendung.css";
import Highlight from "./highlight";
import { StyledLink } from "./StyledLink";

const HomeAnwendung = () => {
	return (
		<div className="homeAnwendung">
			<div className="homeAnwendung__left">
				<h2>
					Garshana, die Anwendung für <Highlight>Dein</Highlight>{" "}
					Morgen-Verwöhn-Ritual
				</h2>
				<AndwendungSvg className="homeAnwendung__svg" />
			</div>
			<div className="homeAnwendung__right">
				<h2>
					Garshana, die Anwendung für <Highlight>Dein</Highlight>{" "}
					Morgen-Verwöhn-Ritual
				</h2>
				<p>
					Beginne Deinen Tag mit einer Selbstmassage. Jeden Morgen ca.
					5-15 min. In dem Du mit ausreichend Druck und Schnelligkeit
					über Deine Haut massierst, bis eine Rötung entsteht. Deinen
					Problemzönchen darfst Du auch gerne etwas mehr
					Aufmerksamkeit schenken. Danach gönnst Du Dir eine
					wohltuende Dusche, um die Toxine und die tote Haut
					abzuwaschen.
				</p>
				<p>
					<StyledLink to="morgenrituale">
						Entdecke weitere ayurvedische Morgenrituale
					</StyledLink>
				</p>
			</div>
		</div>
	);
};

export default HomeAnwendung;
