import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import logo from "../images/pancaIcon2.jpg";
import OrderButton from "./orderButton";
import styled from "@emotion/styled";
import { theme } from "./theme";
import css from "@emotion/css";

const StyledContainer = styled.div`
	margin-bottom: 100px;
	position: relative;

	display: flex;
	align-content: stretch;

	@media (max-width: 1250px) {
		display: flex;
		flex-wrap: wrap;
	}
`;

const StyledTitle = styled.h1`
	font-family: "Indie Flower", cursive;
	line-height: 1.3;
	margin: 0;

	margin-left: 0;

	@media (max-width: 1250px) {
		margin-left: 20px;
	}
`;

const StyledTitleBig = styled.span`
	font-size: 1.3em;

	@media (min-width: 1600px) {
		font-size: 1.5em;
	}
`;
const StyledTitleSmall = styled.span`
	font-size: 0.8em;
`;

const StyledLeft = styled.div`
	background-color: ${theme.primaryColor};
	padding: 1rem 2rem;
	padding-left: 2rem;
	flex-basis: 50%;
	position: relative;

	@media (max-width: 1250px) {
		padding-left: 1rem;
		flex-basis: 100%;
		position: relative;
	}
`;

const StyledRight = styled.div`
	flex-basis: 50%;

	@media (max-width: 1250px) {
		flex-basis: 100%;
	}

	& img {
		display: block;
		margin: 0;
	}
`;

const StyledBottomMobile = styled.div`
	display: none;

	@media (max-width: 1250px) {
		display: block;
		background-color: #b2cca7;
		padding: 1rem 2rem;
		position: relative;
		flex-basis: 100%;
	}
`;

const StyledInfo = styled.div`
	font-size: 1.3em;
	margin-bottom: 2rem;
	margin-top: 4rem;

	${({ mobile }) =>
		mobile
			? css`
					display: none;
					@media (max-width: 1250px) {
						display: block;
						margin-top: 0;
					}
			  `
			: css`
					display: block;
					@media (max-width: 1250px) {
						display: none;
					}
			  `}
`;

const StyledOrderButton = styled(OrderButton)`
	${({ mobile }) =>
		mobile
			? css`
					display: none;
					@media (max-width: 1250px) {
						display: block;
					}
			  `
			: css`
					display: block;
					@media (max-width: 1250px) {
						display: none;
					}
			  `}
`;

const StyledLogo = styled.img`
	height: 130px;
	margin: 0;
	display: block;
`;

const HomeHeader = () => {
	const data = useStaticQuery(graphql`
		query {
			headerImage: file(relativePath: { eq: "headerSmall.png" }) {
				childImageSharp {
					fluid(maxWidth: 800) {
						...GatsbyImageSharpFluid
						presentationWidth
					}
				}
			}
		}
	`);

	return (
		<StyledContainer>
			<StyledLeft>
				<StyledLogo src={logo} alt="Panca" />
				<StyledTitle>
					<StyledTitleBig>Dein Garshana Handschuh</StyledTitleBig>
					<br />
					<StyledTitleSmall>
						khadi für ein glückliches Leben
					</StyledTitleSmall>
				</StyledTitle>
				<StyledInfo>
					Stückpreis 25 CHF <br />
					reduzierter Preis für TherapeutInnen
				</StyledInfo>
				<StyledOrderButton />
			</StyledLeft>
			<StyledRight>
				<Img
					fluid={data.headerImage.childImageSharp.fluid}
					alt="Real Khadi Garshana Handschuh"
					style={{
						height: "100%",
					}}
          critical
          loading="eager"
				/>
			</StyledRight>
			<StyledBottomMobile>
				<StyledInfo mobile>
					Stückpreis 25 CHF <br />
					reduzierter Preis für TherapeutInnen
				</StyledInfo>
				<StyledOrderButton mobile />
			</StyledBottomMobile>
		</StyledContainer>
	);
};

export default HomeHeader;
