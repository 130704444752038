import React from "react"
import "./highlight.css";

const Highlight = ({ children}) => {
	return (
		<span className="highlight">
      {children}
		</span>
	);
}

export default Highlight
