import css from "@emotion/css";

const theme = {
	primaryColor: "#b2cca7",
  mediaMobile: "(max-width: 768px)",
  mediaMobileSmall: "(max-width: 320)",
  titleFontFamily: "Indie Flower, cursive",
};

export { theme };
