import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeHeader from "../components/homeHeader";
import HomeAnwendung from "../components/homeAnwendung";
import HomeBenefits from "../components/homeBenefits";
import HomeAboutMe from "../components/homeAboutMe";
import HomeHerkunft from "../components/homeHerkunft";
import OrderButton from "../components/orderButton";
import { Container } from "../components/container";

const IndexPage = () => (
	<Layout>
		<SEO />
		<HomeHeader />
		<Container>
			<HomeAnwendung />
			<HomeBenefits />
			<HomeAboutMe />
			<div style={{
				textAlign: "center"
			}}>
				<OrderButton />
			</div>
			<HomeHerkunft />
		</Container>
	</Layout>
)

export default IndexPage
