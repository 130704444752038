import React from "react"
import "./orderButton.css";

const OrderButton = ({ style, className }) => {
	return (
		<a href="mailto:info@panca.ch?subject=Garshana Handschuh bestellen&body=Liebe Yvonne,
		Gerne würde ich x Garshana Handschuhe bestellen." className={`${className || ""} orderButton`} style={style}
		>
			Jetzt Bestellen
		</a>
	);
}

export default OrderButton
