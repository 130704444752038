import styled from "@emotion/styled";
import { Link } from "gatsby";
import { theme } from "./theme";

const StyledLink = styled(Link)`
	text-decoration: underline;
	color: ${theme.primaryColor};
`;

export { StyledLink };
