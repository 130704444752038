import React, { useState } from "react"
import Carousel from "nuka-carousel";
import GroesseImage from "../images/groessen.jpg";
import MasseImage from "../images/masse.jpg";
import WashingLabelImage from "../images/washinglabel.jpg";
import HandtagImage from "../images/Handtag.png";
import AyurvedaImage from "../images/Ayurveda.jpg";
import ArrowSvg from "../svg/Arrow.svg";
import "./homeAboutMe.css";

const HomeAboutMe = () => {
	const [slideIndex, setSlideIndex] = useState(0);

	const dots = (
		<ul className="homeAboutMe__dots">
			{[0, 1, 2, 3].map((index) => (
				<li key={index}>
					<button type="button" aria-label="slide 1 bullet" className={index === slideIndex ? "homeAboutMe__dotActive" : ""} onClick={() => setSlideIndex(index)}>
						<span className="paging-dot"></span>
					</button>
				</li>
			))}
		</ul>
	);

	return (
		<div className="homeAboutMe">
			<Carousel slideIndex={slideIndex} withoutControls>
				<span key={1} className="homeAboutMe__carouselItem">
					<img src={GroesseImage} className="homeAboutMe__carouselImage" alt="Drei Grössen" />
					{dots}
					<span className="homeAboutMe__carouselTitle">
						Dein Garshana Handschuh von Panca ist in 3 verschiedenen Grössen verfügbar.
          </span>
					<span className="homeAboutMe__carouselDescription">
						Druch die elastischen Kordeln kannst du die Grösse um Dein Handgelenke selber justieren.
            Messe Deine Handlänge vom Fingerspitz bis zum Handgelenk, sowie die Breite deiner Finger exclusive dem Daumen und finde mit Hilfe der Tabelle Deine passende Grösse.
            <table>
							<thead>
								<tr>
									<th>Panca Handbreite</th>
									<th>Panca Handlänge</th>
									<th>Grösse</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>10 cm</td>
									<td>21.5 cm</td>
									<td>S</td>
								</tr>
								<tr>
									<td>11 cm</td>
									<td>23 cm</td>
									<td>M</td>
								</tr>
								<tr>
									<td>12 cm</td>
									<td>24.5 cm</td>
									<td>L</td>
								</tr>
							</tbody>
						</table>
					</span>
				</span>

				<span key={2} className="homeAboutMe__carouselItem">
					<img src={HandtagImage} className="homeAboutMe__carouselImage" alt="real khadi" />
					{dots}
					<span className="homeAboutMe__carouselTitle">
						Panca, dein Garshana Handschuh, khadi für ein glückliches Leben
          </span>
					<span className="homeAboutMe__carouselDescription">
						Mit Panca hast Du ein Produkt um die Hände, das im Sinne des ayurvedischen Denkens fair und nachhaltig hergestellt wurde.
            Dein Garshana  Handschuh von Panca ist einfaches, wertvolles Stück, mit dem Du Deiner Gesundheit selber etwas zu Liebe tun kannst.
            Der Ursprung ist der AVURVEDA, die Wissenschaft vom langen Leben. Ein ganzheitliches Medizinsystem.
            Panca bedeutet in Sanskrit die Zahl 5, die Zahl ist im Ayurveda von grosser Bedeutung,
            sie taucht in unterschiedlichen Behandlungsformen oder ganz grundlegend in den 5 Elementen den „Panca Mahabhutas“ auf.
					</span>
				</span>

				<span key={3} className="homeAboutMe__carouselItem">
					<img src={WashingLabelImage} className="homeAboutMe__carouselImage" alt="Qualitätssiegel" />
					{dots}
					<span className="homeAboutMe__carouselTitle">
						Qualitätssiegel
          </span>
					<span className="homeAboutMe__carouselDescription">
						Das Gewebe besteht aus Bio-Baumwolle in der Kette und Seide im Schuss.
            Diese Kompostition generiert eine bessere Widerstandsfähigkeit als ein reines Seidengewebe.
            Trotzdem behält es die PH-freundlichen Eigenschaften durch die Seidenproteine. Diese wirken beruhigend auf Deiner Haut.
            Wir empfehlen eine schonende Waschtemperatur von 30-40 Grad. Die Fasernkomposition hat eine antibakrerielle Wirkung und bleibt selbst bei therapeutischer Anwendungen natürlich hygienisch.
					</span>
				</span>
				<span key={4} className="homeAboutMe__carouselItem">
					<img src={AyurvedaImage} className="homeAboutMe__carouselImage" alt="Ayurveda" />
					{dots}
					<span className="homeAboutMe__carouselTitle">Ayurveda</span>
					<span className="homeAboutMe__carouselDescription">
						<p>
							Die 5 Elemente Wasser, Erde, Feuer, Luft und Äther, sind als substanzielle, grobstoffliche Grundlagen für die universellen Gesetzmässigkeiten der Naturkräfte zu verstehen.
							Sie manifestieren sich im Körper als biophysikalische Wirkungsprinzipien und sind in der Lage bestimmte Eigenschaften und Funktionen im Körper hervorzurufen.
							Gruppierung von Eigenschaften welche uns zu verstehen geben, was im Körper vor sich geht nennt man Doshas. Diese Doshas bestehen aus je zwei Elementen;
						</p>
						<ul>
							<li>VATA (Äther und Luft)</li>
							<li>PITTA (Feuer und wenig Wasser/Öl)</li>
							<li>KAPHA (Erde und Wasser)</li>
						</ul>
						<p>
							<a href="https://www.anyveda.ch" target="__blank">Mehr Infos über Ayurveda findest du hier.</a>
						</p>
						{false && <>Die Qualitäten der Doshas werden zur Beschreibung aller Erscheinungen eingesetzt.
            Auch zur Bestimmung der persönlichen Konstitution (d.h. die individuelle Mischung der fünf Elemente), der Menschen.
            Die Kenntnis dieser Mischung dient als Grundlage des Ayurvedas, um ausgleichende Empfehlungen und Behandlungen zu bestimmen.</>}
					</span>
				</span>
			</Carousel>
			<button onClick={() => {
				if (slideIndex === 0) {
					setSlideIndex(3);
				} else {
					setSlideIndex(slideIndex - 1);
				}
			}} type="button" className="homeAboutMe__prevButton"><ArrowSvg /></button>
			<button onClick={() => {
				if (slideIndex === 3) {
					setSlideIndex(0);
				} else {
					setSlideIndex(slideIndex + 1);
				}
			}} type="button" className="homeAboutMe__nextButton"><ArrowSvg /></button>
		</div>
	);
}

export default HomeAboutMe
