import React from "react";
import KreislaufImage from "../images/kreislaufv2.gif";
import SternchenImage from "../images/sternchen.gif";
import HappyImage from "../images/happy.gif";
import DigestImage from "../images/digest.gif";
import DetoxImage from "../images/detox1v2.gif";
import ZelluliteImage from "../images/zellulitev2.gif";
import "./homeBenefits.css";
import Highlight from "./highlight";
import { StyledLink } from "./StyledLink";
import styled from "@emotion/styled";
import css from "@emotion/css";

const StyledContainer = styled.div`
	margin: 100px 0;
`;

const StyledLinkContainer = styled.div`
	text-align: center;
	margin-top: 20px;
`;

const StyledImage = styled.img`
	height: 100px;

	${({ small }) => small && css`
		height: 50px;
	`}
`;


const HomeBenefits = () => {
	return (
		<StyledContainer>
			<div className="homeBenefits">
				<h2>
					Ich tue <Highlight>Dir</Highlight> was Gutes
				</h2>
				<ul>
					<li>
						<span className="homeBenefits_pikto">
							<StyledImage
								small
								src={SternchenImage}
								className="homeBenefits__image"
								alt="Verwöhne deine Haut"
							/>
						</span>
						<span className="homeBenefits_label">
							Verwöhne deine Haut
						</span>
					</li>
					<li>
						<span className="homeBenefits_pikto">
							<StyledImage
								src={DigestImage}
								className="homeBenefits__digest"
								alt="Fördere deine Verdauung"
							/>
						</span>
						<span className="homeBenefits_label">
							Fördere deine Verdauung
						</span>
					</li>
					<li>
						<span className="homeBenefits_pikto">
							<StyledImage
								src={DetoxImage}
								className="homeBenefits__image"
								alt="Unterstüzte dein natürliches Detoxing"
							/>
						</span>
						<span className="homeBenefits_label">
							Unterstütze dein natürliches Detoxing
						</span>
					</li>
					<li>
						<span className="homeBenefits_pikto">
							<StyledImage
								src={KreislaufImage}
								className="homeBenefits__image"
								alt="Rege deinen Kreislauf an"
							/>
						</span>
						<span className="homeBenefits_label">
							Rege deinen Kreislauf an
						</span>
					</li>
					<li>
						<span className="homeBenefits_pikto">
							<StyledImage
								src={ZelluliteImage}
								className="homeBenefits__image"
								alt="Straffe deine Haut"
							/>
						</span>
						<span className="homeBenefits_label">
							Straffe deine Haut
						</span>
					</li>
					<li>
						<span className="homeBenefits_pikto">
							<StyledImage
								src={HappyImage}
								small
								className="homeBenefits__image"
								alt="Mach dich glücklich"
							/>
						</span>
						<span className="homeBenefits_label">
							Mach dich glücklich
						</span>
					</li>
				</ul>
			</div>

			<StyledLinkContainer>
				<StyledLink to="kontraindikatoren">
					Finde heraus, wann die Anwendung vorerst abgeklärt werden
					muss.
				</StyledLink>
			</StyledLinkContainer>
		</StyledContainer>
	);
};

export default HomeBenefits;
