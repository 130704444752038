import React from "react"
import "./homeHerkunft.css";
import YvonneImage from "../images/YvonneBoos2.png";
import SukhdevImage from "../images/Sukhdev.png";
import ManishaImage from "../images/Manisha.png";
import HandspunImage from "../images/handspun.gif";
import HanswovenImage from "../images/handwoven.gif";
import Highlight from "./highlight";

const HomeHerkunft = () => {

	return (
		<div className="homeHerkunft">
			<h3>Khadi Phylosophie</h3>
			<ul className="homeHerkunft__gifContainer">
				<li>
					<span className="homeHerkunft_pikto"><img src={HandspunImage} className="homeHerkunft__gif" alt="Handgesponnen" /></span>
					<span className="homeHerkunft_label">Handgesponnen</span>
				</li>
				<li>
					<span className="homeHerkunft_pikto"><img src={HanswovenImage} className="homeHerkunft__gif" alt="Handgewoben" /></span>
					<span className="homeHerkunft_label">Handgewoben</span>
				</li>
			</ul>
			<p>
				<a href="https://en.wikipedia.org/wiki/Khadi" target="__blank">Khadi</a> bedeutet hand gesponnen, hand gewebte Naturfaserprodukte.
        1918 startete Mahatma Gandhi die Bewegung  als Unterstützunsprogramm der Dorfbefölkerung in Indien um den Menschen die Selbsbestimmung zurückzugeben.
        Jedes Dorf sollte seine eigenen Rohstoffe selber pflanzen, ernten, die Fasern zu Garn verspinnen um daraus Stoffe für den Eigengebrauch zu weben.
        Viele Bauern damals wie heute haben nicht genug Arbeit um ihr Einkommen zu sichern.
        Die Philosophie wird noch immer so gelebt. Ein schönes Beispiel dafür ist  die Organisation Aavran Handlooms.
        Mit dem Kauf Deines Panca Garshana Handschuh unterstützt Du die Handweberinnen und -weber sowie die Bio-Bauernfamilien.
        Denn nur wenn sie ihre Produkte verkaufen können, lebt ihre traditionelle und nachhaltige Arbeit weiter.
					</p>

			<h3>Personen hinter dem Produkt</h3>
			<p>
				Eine der Weberinnen heisst Manisha, wohnt in Choti Khargone, Indien.
				Der Leiter des Handwebstuhlteams von Aavran heisst Sukhdev und wohnt in Bablai, Indien.
        Entwickelt wurde ich von Yvonne wohnhaft in Zürich, Schweiz. Neben ihrem Beruf als Designerin ist sie im Studium zur Komplementär TherapeutIn mit eidg. Diplom.<br />
				Einen Film über Aavran Handlooms findest du hier: <a href="https://www.youtube.com/watch?v=4JiPObMSBbQ" target="__blank">https://www.youtube.com/watch?v=4JiPObMSBbQ</a>
			</p>
			<div className="homeHerkunft__images">
				<div>
					<img src={ManishaImage} alt="Manisha" />
					<span>Manisha</span>
				</div>
				<div>
					<img src={SukhdevImage} alt="Sukhdev" />
					<span>Sukhdev</span>
				</div>
				<div>
					<img src={YvonneImage} alt="Yvonne Boos" />
					<span>Yvonne</span>
				</div>
			</div>

			<h3>Über Aavran Handlooms</h3>
			<p>
				In diesem von der bioRe® Stiftung initiierten Livelihood Projekt verarbeiten Frauen die lokal angebaute Bio-Baumwolle in sorgfältiger Handarbeit zu Garn und Webwaren und sichern so ihre Existenz. Für die Ausbildung im Dorfzentrum oder auch direkt bei ihnen zu Hause, stellt die bioRe® Stiftung Handspinnmaschinen und Webstühle zur Verfügung.
					</p>
			<p>
				Ziel Förderung von Einkommen im ländlichen Indien und Produktion von einzigartigen, handgefertigten Produkten aus Bio-Baumwolle.

					</p>
			<p>
				Wahre Handarbeit Die Mitarbeiterinnen von Aavran Handloom verarbeiten das gesponnene Garn im bioRe® Handloom Ausbildungszentrum in Handarbeit zu Gewebe weiter. Die bioRe® Stiftung stellt die Webstühle zur Verfügung. Vorab erhalten die Mitarbeiterinnen von Aavran Handloom eine gute Ausbildung über die Techniken des Webens und das Handhaben der Webstühle. Um die Qualität des Trainings sicherzustellen, arbeitet die bioRe® Stiftung mit der lokalen NGO, Womens Weave, zusammen. Das Projekt arbeitet mit lokalen Frauen und jungen Männern. Frauen werden im Handspinnen beschäftigt, Jugendliche erlernen das Weben und werden für das Gewebe bezahlt. Wodurch sie ihre Einkommensmöglichkeiten stark ausbauen können.

					</p>
			<p>
				Vision Sich als qualitativ hochwertige Service-Organisation zu entwickeln, die die Lebensgrundlage der ländlichen Bevölkerung stärkt, die ökonomische Unabhängigkeit der Frauen fördert und als umweltfreundliche Organisation hangesponnene und -gewobene Naturprodukte herstellt. So sollen sich gewissenhafte und verantwortungsvolle Gemeinschaften entwickeln.

					</p>
			<p>
				Weitere Auskünfte findest du hier: <a href="http://www.aavranhandlooms.com" target="__blank">http://www.aavranhandlooms.com</a>
			</p>
		</div>
	);
}

export default HomeHerkunft
